import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

//reusable component
import ServicesLinks from "../components/services/ServicesLinks"

//images
import SolutionsImgBtm from "../images/solutions-hero-bottom.png"
// import GoldenStripImg from "../images/golden-strip.svg"
import PartnerIconImg from "../images/partner-icon.svg"
// import Checkicon from "../images/check-yellow.svg"
// import PremFinanceTeam from "../images/prem-finance-team.png"
// import GoldenBoxImg from "../images/golden-box.svg"

//data

const Solutions = () => {
  return (
    <Layout>
      <SEO title="Solutions - National Partners" description="" />
      <div className="page-headline">
        <div className="hero-headline solutions-page">
          <div className="container">
            <div className="row">
              {/* <div className="section-heading text-center">
                <div className="hero-body"> */}
              <div className="col-lg-10 col-md-9 mx-lg-auto mx-md-auto">
                <h1 className="hero-page-title">
                  Premium Financing Solutions from National Partners
                </h1>
              </div>
              <div className="col-lg-7 col-md-9 mx-lg-auto mx-md-auto">
                <h2 className="hero-subtitle text-white">
                  National Partners focus on building long-term relationships
                  with our agent clients, so we can provide the right solution
                  that will meet your needs now and as they evolve over time.
                </h2>
              </div>
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>
        <img
          src={SolutionsImgBtm}
          className="hero-bottom-img img-fluid w-100"
          alt="Meeting"
        />
      </div>
      <div className="page-content pb-280 pt-0 solutions-wrapper">
        <section className="producer-page pt-5">
          <article>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-lg-auto">
                  <div className="section-heading text-center">
                    <h6 className="section-subtitle text-gold text-uppercase">
                      our services
                    </h6>
                    <hr className="section-hr" />
                    <h2 className="section-title pb-0">
                      Premium Financing Solutions from National Partners
                    </h2>
                  </div>
                </div>
                <div
                  className="col-lg-10 col-md-12 col-12 text-center mx-lg-auto mx-md-auto my-lg-5 my-md-5 my-5"
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "20px",
                    lineHeight: "30px",
                  }}
                >
                  <p className="section-description">
                    National Partners focus on building long-term relationships
                    with our agent clients, so we can provide the right solution
                    that will meet your needs now and as they evolve over time.
                  </p>
                </div>
              </div>
            </div>
          </article>
          <article>
            <ServicesLinks />
          </article>
        </section>
        <section
          className="empowering-knowledge-wrapper"
          style={{ position: "relative" }}
        >
          <iframe
            // style="width: 100%; height: 100px; overflow: hidden;"
            src="https://secure.financepro.net/financepro/default.aspx"
            width="100"
            height="100"
            scrolling="no"
          >
            Iframes not supported
          </iframe>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10 mx-lg-0 mx-md-auto text-white">
                <h2 className="section-title text-white">
                  Empowering Knowledge and Growth
                </h2>
                <p className="section-description text-white">
                  National Partners is knowledgeable about your business. We
                  understand premium finance and what you need to succeed. We
                  will provide funding specific to your needs and objectives.
                </p>
                <p className="section-description text-white">
                  National Partners can provide the funds to help you grow. When
                  it comes to eligible contracts, we take the risk and do not
                  require personal guarantees. Your actual portfolio determines
                  your eligibility, not financial statement ratios or tests.
                </p>
                <p className="section-description text-white">
                  We are also easy to work with. Our exclusive LATTe software
                  does all the work, including the daily eligible report. You
                  get a simple process to access loan funds, with eligibility
                  and reporting done automatically.
                </p>
                <p className="section-description text-white">
                  Once our data request is delivered, there are no delays.
                  Without a faceless loan committee, we can increase your
                  funding when opportunities arise. National Partners move
                  quickly and efficiently.
                </p>
              </div>
              <div className="col-lg-6 col-md-10 mx-auto mt-lg-0 mt-md-5 mt-5">
                <div className="empowering-right-content">
                  <div style={{ width: "50%", margin: "0 auto" }}>
                    <img
                      src={PartnerIconImg}
                      alt="Handshake"
                      className="img-fluid"
                    />
                  </div>
                  <h2 className="section-title">
                    Why Partner with National Partners?
                  </h2>
                  <p className="section-description">
                    If you are looking for Premium Financing Solutions that can
                    thoroughly satisfy your needs and help your business reach
                    your goals, then a partnership with National Partners won’t
                    let you down.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="advantages-wrapper"
          style={{
            background: "linear-gradient(180deg, #F1F6FF 0%, #FFFFFF 100%)",
            paddingTop: "15rem",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h2 className="section-title text-capitalize">
                  Here are the advantages you will gain when working with us:
                </h2>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <ul>
                  <li>
                    Dedicated Service Team with decades of industry experience
                  </li>
                  <li>Integration with several Agency</li>
                  <li>Management Systems</li>
                  <li>Technology Solutions to improve efficiencies</li>
                  <li>Integration with DocuSign</li>
                  <li>
                    Multiple quote options so client may determine best
                    course-of-action for business
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                  <li>E-mail payment reminders may be sent to the client</li>
                  <li>
                    Agency on-line access allows for:
                    <ul>
                      <li>Re-printing of notices</li>
                      <li>Funding disbursement information</li>
                      <li>Client payoff figures</li>
                      <li>Customer payment details</li>
                      <li>
                        Cancellation Prevention & Retention (CPR) - helps
                        insureds stay current and keep commissions intact
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="premium-finance-copy py-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-lg-auto">
                <div
                  className="national-partners-solutions-contact"
                  style={{
                    background:
                      "linear-gradient(180deg, #F1F6FF 0%, #FFFFFF 100%)",
                    borderRadius: "20px",
                    padding: "5rem",
                  }}
                >
                  <h2 className="section-title">
                    National Partners Premium Finance Solutions
                  </h2>
                  <p className="section-description">
                    National Partners has unique programs to help you see more
                    opportunities. If you have policies which are larger than
                    prudent for your portfolio, we have solutions! If you have
                    insurance company concentration issues, we will “underwrite
                    them for eligibility”.
                  </p>
                  <p className="section-description">
                    Our Senior Management is available to work with you.
                  </p>
                  <div style={{ marginTop: "5rem" }}>
                    <Link to="/contact-us" className="contact-btn">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Solutions
