import React from "react"
import { Link } from "gatsby"

import { ServicesLinksData } from "../data/data"

const ServicesLinks = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          {ServicesLinksData.map(services => (
            <div
              className="col-lg-2 col-md-4 col-6 px-lg-2 px-md-auto mt-lg-0 mt-md-5 mt-5 text-center h-auto"
              key={services.title}
              // style={{ maxHeight: "auto" }}
            >
              <Link to={services.link} className="services-links-wrapper h-100">
                <div
                  dangerouslySetInnerHTML={{
                    __html: services.img,
                  }}
                />
                <div
                  className="services-title mt-3"
                  dangerouslySetInnerHTML={{
                    __html: services.title,
                  }}
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ServicesLinks
